import Vue from 'vue';
import {
    StripePlugin,
    StripeElementCard,
} from '@vue-stripe/vue-stripe';

export default () => {
    Vue.component('StripeElementCard', StripeElementCard);
    Vue.use(StripePlugin, { pk: 'pk_test_51NJ3IPIAeP1vmy4sqNeeNmAY1nqyVWMLQ2QxXxb7iIiGlmr4MbPOc9RHhF91lYEAPcTQDEIyt10lhgdVOPuqG268002FkzqFy2' });
};
