export const CardsGrid = () => import('../../components/CardsGrid.vue' /* webpackChunkName: "components/cards-grid" */).then(c => wrapFunctional(c.default || c))
export const ConfirmationDialog = () => import('../../components/ConfirmationDialog.vue' /* webpackChunkName: "components/confirmation-dialog" */).then(c => wrapFunctional(c.default || c))
export const ContentRenderer = () => import('../../components/ContentRenderer.vue' /* webpackChunkName: "components/content-renderer" */).then(c => wrapFunctional(c.default || c))
export const CtaSection = () => import('../../components/CtaSection.vue' /* webpackChunkName: "components/cta-section" */).then(c => wrapFunctional(c.default || c))
export const FaqList = () => import('../../components/FaqList.vue' /* webpackChunkName: "components/faq-list" */).then(c => wrapFunctional(c.default || c))
export const FormField = () => import('../../components/FormField.vue' /* webpackChunkName: "components/form-field" */).then(c => wrapFunctional(c.default || c))
export const ImgBlock = () => import('../../components/ImgBlock.vue' /* webpackChunkName: "components/img-block" */).then(c => wrapFunctional(c.default || c))
export const MarkdownBlock = () => import('../../components/MarkdownBlock.vue' /* webpackChunkName: "components/markdown-block" */).then(c => wrapFunctional(c.default || c))
export const MarqueeSlider = () => import('../../components/MarqueeSlider.vue' /* webpackChunkName: "components/marquee-slider" */).then(c => wrapFunctional(c.default || c))
export const PageFooter = () => import('../../components/PageFooter.vue' /* webpackChunkName: "components/page-footer" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/PageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PlansList = () => import('../../components/PlansList.vue' /* webpackChunkName: "components/plans-list" */).then(c => wrapFunctional(c.default || c))
export const PlansListForPlansPage = () => import('../../components/PlansListForPlansPage.vue' /* webpackChunkName: "components/plans-list-for-plans-page" */).then(c => wrapFunctional(c.default || c))
export const Preloader = () => import('../../components/Preloader.vue' /* webpackChunkName: "components/preloader" */).then(c => wrapFunctional(c.default || c))
export const RichBlock = () => import('../../components/RichBlock.vue' /* webpackChunkName: "components/rich-block" */).then(c => wrapFunctional(c.default || c))
export const SectionHeading = () => import('../../components/SectionHeading.vue' /* webpackChunkName: "components/section-heading" */).then(c => wrapFunctional(c.default || c))
export const ThumbsSlider = () => import('../../components/ThumbsSlider.vue' /* webpackChunkName: "components/thumbs-slider" */).then(c => wrapFunctional(c.default || c))
export const UiBtn = () => import('../../components/UiBtn.vue' /* webpackChunkName: "components/ui-btn" */).then(c => wrapFunctional(c.default || c))
export const UiDropdownItem = () => import('../../components/UiDropdownItem.vue' /* webpackChunkName: "components/ui-dropdown-item" */).then(c => wrapFunctional(c.default || c))
export const UiIcon = () => import('../../components/UiIcon.vue' /* webpackChunkName: "components/ui-icon" */).then(c => wrapFunctional(c.default || c))
export const UiSection = () => import('../../components/UiSection.vue' /* webpackChunkName: "components/ui-section" */).then(c => wrapFunctional(c.default || c))
export const UserDropdown = () => import('../../components/UserDropdown.vue' /* webpackChunkName: "components/user-dropdown" */).then(c => wrapFunctional(c.default || c))
export const VideoBlock = () => import('../../components/VideoBlock.vue' /* webpackChunkName: "components/video-block" */).then(c => wrapFunctional(c.default || c))
export const RectangleBgCard = () => import('../../components/RectangleBgCard/RectangleBgCard.vue' /* webpackChunkName: "components/rectangle-bg-card" */).then(c => wrapFunctional(c.default || c))
export const RectangleBgCardBlock = () => import('../../components/RectangleBgCard/RectangleBgCardBlock.vue' /* webpackChunkName: "components/rectangle-bg-card-block" */).then(c => wrapFunctional(c.default || c))
export const ShapeBgCard = () => import('../../components/ShapeBgCard/ShapeBgCard.vue' /* webpackChunkName: "components/shape-bg-card" */).then(c => wrapFunctional(c.default || c))
export const ShapeBgCardBlock = () => import('../../components/ShapeBgCard/ShapeBgCardBlock.vue' /* webpackChunkName: "components/shape-bg-card-block" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
