
export default {
  name: 'UiIcon',
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    iconMaterial () {
      return this.icon.slice(0, 3) === 'mi-'
    },
    iconMaterialFilled () {
      return this.icon.slice(0, 3) === 'mf-'
    },
    iconIcomoon () {
      return this.icon.slice(0, 3) === 'im-'
    },
    iconName () {
      return this.icon.slice(3)
    }
  }
}
