
import { isLinkExternal } from '@/helpers'
export default {
  name: 'UiDropdownItem',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    divided: {
      type: Boolean,
      default: false
    },
    to: {
      type: [String, Object],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'default'
    },
    prependIcon: {
      type: String,
      default: null
    },
    prependIconClass: {
      type: String,
      default: null
    },
    appendIcon: {
      type: String,
      default: null
    },
    appendIconClass: {
      type: String,
      default: null
    },
    labelClass: {
      type: String,
      default: null
    },
    targetBlank: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    externalResource () {
      return isLinkExternal(this.to)
    },
    component () {
      if (!this.to) { return 'button' }
      if (!this.externalResource) { return 'nuxt-link' }
      return 'a'
    }
  }
}
