export const state = () => ({
  isAuthenticated: false,
  customer: {
    id: "",
    email: "",
    name: "",
    stripe: {},
    premium: {},
    trello: "",
    authProviders: [],
  },
});

export const getters = {
  getInitials: (state) => {
    if (
      state.customer.name?.length > 0 &&
      state.customer.name?.split(" ").length > 1
    ) {
      return (
        state.customer.name.split(" ")[0][0] +
        state.customer.name.split(" ")[1][0]
      ).toUpperCase();
    }
    return "";
  },
};

export const mutations = {
  setUser(state, payload) {
    state.customer = payload;
  },
  clearUser(state) {
    state.customer = {
      id: "",
      email: "",
      name: "",
      stripe: {},
      premium: {},
      trello: "",
    };
  },
  setName(state, payload) {
    state.customer.name = payload;
  },
  login(state) {
    state.isAuthenticated = true;
  },
  logOut(state) {
    state.isAuthenticated = false;
  },
};

export const actions = {
  async updateUserData(context, ctx) {
    const { data } = await ctx
      .$axios("/customers/me", { withCredentials: true })
      .catch();
    if (data.user) {
      context.commit("setUser", data.user);
    } else {
      context.commit("logOut");
    }
  },
  async logout(context, ctx) {
    const { data } = await ctx.$axios
      .post("/customers/logout", {}, { withCredentials: true })
      .catch()
      .finally(() => {
        context.commit("logOut");
        context.commit("clearUser");
      });
  },
  async login(context, ctx) {
    // const { data } = await ctx.$axios('/customers/me',  {withCredentials: true}).catch()
    // if (data.user) {
    //     context.commit('setUser', data.user)
    //     context.commit('login')
    // }else {
    //     context.commit('logOut')
    // }
  },
  async nuxtServerInit({ commit }) {
    const { data } = await this.$axios("/customers/me", {
      headers: {
        Accept: "application/json",
      },
      withCredentials: true,
    }).catch((error) => console.log(error));

    if (data?.user) {
      commit("setUser", data.user);
      commit("login");
    } else {
      commit("logOut");
    }
  },
};
