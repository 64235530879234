import Vue from 'vue'
import {
  Input,
  Select,
  Option,
  OptionGroup,
  Popover,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Dialog,
  Message,
  Collapse,
  CollapseItem,
  Tooltip,
  Checkbox,
  Alert

} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

// configure language
locale.use(lang)

Vue.use(Input)
Vue.use(Select)
Vue.use(OptionGroup)
Vue.use(Option)
Vue.use(Popover)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Dialog)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Tooltip)
Vue.use(Checkbox)
Vue.use(Alert)

Vue.prototype.$message = Message
