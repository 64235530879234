
import {logOut} from '../helpers/auth';
  export default {
  name: 'UserDropdown',
    props: {
      initials: String,
      trello: String
    },
    data () {
      return {
        isOpened: false
      }
    },
    methods: {
      logOutClick(){
        this.$store.dispatch('logout', this)
      }
    }
}
