export const isLinkExternal = link => {
  if (!link) {
    return false
  }
  return link.toString().includes('http')
}


export const richTextConstructor = (array) => {
  let result = ''
  array.forEach(elem => {
    if (elem.type) {
      result += `<${elem.type}>${elem.children.map(e => e.text).join('')}</${elem.type}> `
    }else {
      result +=  `<p>${PConstructor(elem.children)}</p>`
    }
  })
  return result
}

const PConstructor = (array) => {
  let result = ''
  array.forEach(elem => {
    if (elem.type === 'link') {
      result += `<a href="${elem.url}">${elem.children[0].text}</a>`
    }else{
      if (elem.bold) result += `<b>${elem.text}</b> `
      else if (elem.italic) result += `<i>${elem.text}</i> `
      else result += elem.text
    }
  })
  return result
}