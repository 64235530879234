
export default {
  name: 'PageFooter',

  data () {
    return {
      nav: [
        { buttonText: 'ITG', to: 'itg.digital', highlighted: true, targetBlank: true },
        { buttonText: 'Pricing', to: '/' },
        { buttonText: 'Request', to: '/' },
        { buttonText: 'FAQ', to: '/' },
        { buttonText: 'Privacy Policy', to: '/' },
        { buttonText: 'Terms of use', to: '/' }
      ],
      socials: [
        // { to: 'javascript:;', icon: 'fb' },
        // { to: 'javascript:;', icon: 'twitter' },
        // { to: 'javascript:;', icon: 'inst' },
        { to: 'https://dribbble.com/noomoagency', icon: 'dribble' }
        // { to: 'javascript:;', icon: 'behance' }
      ]
    }
  },
  async mounted(){
    const data = await this.$axios('/globals/footer')
    if (!data.data?.items) return 

    this.nav = data.data.items.map(elem => {
      if(elem.button.type === "external"){
        return {title: elem.button.buttonText, to: elem.button.to}
      }else{
        if ("/" === elem.button.to?.url) {
          return {title: elem.button.buttonText, to: elem.button.to?.url, highlighted: true, targetBlank: true }
        }else{
          return {title: elem.button.buttonText, to: elem.button.to?.url}
        }
      }
   })
  }
}
