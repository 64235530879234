import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fb89b83a = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _51acb643 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _25e9fb58 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _56a38e72 = () => interopDefault(import('../pages/profile/account.vue' /* webpackChunkName: "pages/profile/account" */))
const _719abc6a = () => interopDefault(import('../pages/profile/payment.vue' /* webpackChunkName: "pages/profile/payment" */))
const _b9cec122 = () => interopDefault(import('../pages/profile/plans.vue' /* webpackChunkName: "pages/profile/plans" */))
const _52c06d04 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _477475e0 = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _5c0acc56 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _6732cda6 = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _03b76dbd = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5e174f6c = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _fb89b83a,
    name: "404"
  }, {
    path: "/change-password",
    component: _51acb643,
    name: "change-password"
  }, {
    path: "/profile",
    component: _25e9fb58,
    name: "profile",
    children: [{
      path: "account",
      component: _56a38e72,
      name: "profile-account"
    }, {
      path: "payment",
      component: _719abc6a,
      name: "profile-payment"
    }, {
      path: "plans",
      component: _b9cec122,
      name: "profile-plans"
    }]
  }, {
    path: "/reset-password",
    component: _52c06d04,
    name: "reset-password"
  }, {
    path: "/sign-in",
    component: _477475e0,
    name: "sign-in"
  }, {
    path: "/sign-up",
    component: _5c0acc56,
    name: "sign-up"
  }, {
    path: "/verify-email",
    component: _6732cda6,
    name: "verify-email"
  }, {
    path: "/",
    component: _03b76dbd,
    name: "index"
  }, {
    path: "/*",
    component: _5e174f6c,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
