
export default {
  name: 'PageHeader',
  data () {
    return {
      showMenu: false,
      scrolled: false,
      nav: [
        { to: '/', title: 'itg.builder' }
      ]
    }
  },
  computed: {
    initials() {
      return this.$store.getters.getInitials
    }
  },
  async mounted () {
    const onScrollHandler = () => {
      if (window.scrollY > 120) {
        this.scrolled = true
      } else { this.scrolled = false }
    }
    onScrollHandler()
    window.addEventListener('scroll', onScrollHandler)


    try {
      const data = await this.$axios('/globals/nav')
      if(data.data.items)
        this.nav = data.data.items.map(elem => {
            return {title: elem.button.buttonText, to: elem.button.to.url ? elem.button.to.url : elem.button.to}
        })
    }catch (e) {
      console.log(e)
    }
  }
}
