import Vue from 'vue'


export const state = new Vue({
    data: {
        isAuthenticated: process.client ? !!localStorage.getItem('token') : false,
        email: '',
        customer: {
            id: '',
            email: '',
            name: '',
            stripe: {},
            premium: {},
            trello: ''
        }
    }
})

export function setCustomer (args) {
    const { email, stripe, name, id, premium, trello } = args
    state.customer.id = id
    state.customer.email = email
    state.customer.name = name ?? ''
    state.customer.premium = premium
    state.customer.stripe = stripe
    state.customer.trello = trello ?? ''
}

export function getMe () {
  return state.customer
}

export function getId () {
    return state.customer.id
}

export function getEmail () {
    return state.customer.email
}
export function getName () {
    return state.customer.name
}
export function setName (name) {
    state.customer.name = name
}

export function isPremium() {
  return state.customer.premium.status
}

export function getPlan () {
  return state.customer.premium.plan
}
export function getTrelloUrl () {
    return state.customer.trello
}

export function getPaymentMethod () {
    return state.customer.paymentMethodId
}
export function setPaymentMethod (paymentMethod) {
  state.customer.paymentMethodId = paymentMethod
}

export const login = (token) =>{
 localStorage.setItem('token', token) //res.data.token)
 state.isAuthenticated = true
}

export const reLogin = () =>{
    state.isAuthenticated = true
}

export function logOut (refresh = true) {

    if (process.client) { //payload-token
        localStorage.removeItem('token')
        state.isAuthenticated = false
    }
}

export function getToken () {
    return localStorage.getItem('token')
}

