
import { isLinkExternal } from '@/helpers'
export default {
  name: 'UiBtn',
  props: {
    label: {
      type: String,
      default: null
    },
    to: {
      type: [String, Object],
      default: null
    },
    targetBlank: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    },
    prependIcon: {
      type: String,
      required: false
    },
    appendIcon: {
      type: String,
      required: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'm'
    },
    iconOnly: {
      type: String,
      default: null
    },
    uppercase: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    externalResource () {
      return isLinkExternal(this.to?.url)
    },
    component () {
      if (!this.to) { return 'button' }
      if (!this.externalResource) { return 'nuxt-link' }
      return 'a'
    }
  }
}
